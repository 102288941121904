import React from "react"
import { useTranslation } from 'react-i18next';
import { Button } from ".";

const ButtonDownload = ({ onClick = () => { }, downloading, disabled }) => {

    const { t } = useTranslation();

    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            className="button-export"
        >
            {downloading ? t('Downloading...') : t('Export')}
        </Button>
    )
};


export default ButtonDownload;
